import React from 'react'

const Footer = () => {
  return (
    <footer>
<div class=" mt-auto fixed-bottom1">
      <div class="container  p-3">
        <div class="d-flex align-items-center justify-content-between small text-center">
          <div class="text-muted">
            <span class="copyright quick-links footer_height">
              © COPYRIGHT 2023 <a href="/dashboard">ORPECT LLC.</a> All
              Rights Reserved.
            </span>
          </div>
        </div>
        </div>
        </div>
    </footer>
 
    
   
  )
}

export default Footer