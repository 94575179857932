import React from 'react'
import Coming_soon_image from "../asset/images/coming_soon.png"

const Coming_soon = () => {
  return (
    <>
      <div className='coming_soon_image'>
        <img src={Coming_soon_image} width={400}/>
      </div>
    </>
  )
}

export default Coming_soon